<template>
  <div class="table-container">
    <table class="responsive-table">
      <thead>
        <tr>
          <th>Matrice</th>
          <th>Dimensions</th>
          <th>Antennes</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in tableData" :key="item.format">
          <td>{{ item.format }}</td>
          <td>{{ item.dimensions }}</td>
          <td>{{ item.points }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'TableauFormatsResponsive',
  data() {
    return {
      tableData: [
        { format: 'J', dimensions: 'H10 x 150 x 300 mm', points: 18 },
        { format: 'A4', dimensions: 'H10 x 240 x 315 mm', points: 30 },
        { format: 'A3', dimensions: 'H10 x 270 x 500 mm', points: 54 },
        { format: 'A2', dimensions: 'H10 x 420 x 600 mm', points: 100 },
        { format: 'A1', dimensions: 'H10 x 600 x 850 mm', points: 204 },
        { format: 'A0', dimensions: 'H10 x 850 x 1200 mm', points: 408 },
      ]
    };
  }
};
</script>

<style scoped lang="scss">
.table-container {
  padding: 1rem;
  overflow-x: auto;
}

.responsive-table {
  width: 100%;
  border-collapse: collapse;

  th, td {
    padding: 0.75rem;
    border: 1px solid #ddd;
    text-align: left;
    white-space: nowrap;
  }

  th {
    background-color: #f4f4f4;
  }

  @media (max-width: 768px) {
    th, td {
      padding: 0.5rem;
      font-size: 0.9rem;
    }
  }
}
</style>