<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" sm="10" lg="10" xl="8">
        <div class="plateformes-container shadow">
          <div class="list-container">
            <ul class="row-around item-implant">
              <li :class="{ active: button == 1, inactive: button != 1 }">
                <button @click="button = 1">
                  <h3 :class="{ active: button == 1 }">
                    {{ this.$t("pageMatrice.matrice[0].title") }}
                  </h3>
                </button>
                <div :class="{ active: button == 1 }"></div>
              </li>
              <li :class="{ active: button == 2, inactive: button != 3 }">
                <button @click="button = 2">
                  <h3 :class="{ active: button == 2 }">
                    {{ this.$t("pageMatrice.matrice[2].title") }}
                  </h3>
                </button>
                <div :class="{ active: button == 2 }"></div>
              </li>
            </ul>
          </div>
          <div class="implant-container-inferior row-around">
            <div class="part-1">
              <div class="image-implant-container">
                <img src="@/assets/illustrations/MiceTrackPro.webp" alt="" :class="{ active: button == 1 }" />
                <tableau-formats-matrice v-if="button === 1" />
                <img src="@/assets/illustrations/MiceReader.webp" alt="" :class="{ active: button == 2 }" />
              </div>
            </div>
            <div class="part-2">
              <div class="description">
                <div class="information-implant" :class="{ active: button == 1 }">
                  <h2 class="title-implant">
                    {{ this.$t("pageMatrice.matrice[0].title") }}
                  </h2>
                  <!-- <p>{{ this.$t('pageMatrice.matrice[0].dimension')}}</p> -->

                  <p>{{ this.$t("pageMatrice.matrice[0].texte") }}</p>
                </div>
                <div class="information-implant" :class="{ active: button == 2 }">
                  <h2 class="title-implant">
                    {{ this.$t("pageMatrice.matrice[2].title") }}
                  </h2>
                  <p>{{ this.$t("pageMatrice.matrice[2].dimension") }}</p>
                  <p>{{ this.$t("pageMatrice.matrice[2].texte") }}</p>
                </div>
              </div>
              <div class="capteurs">
                <div class="row-around background-capteurs">
                  <ul class="list-capteurs">
                    <li :class="{ activeico: true }">
                      <img src="@/assets/ico/bienEtre.webp" alt="" />
                      <p>{{ $t("pageMatrice.accordionItems[0].title") }}</p>
                    </li>
                    <li :class="{ activeico: true }">
                      <img src="@/assets/ico/RoueViolette.webp" alt="" />
                      <p>{{ $t("pageMatrice.accordionItems[1].title") }}</p>
                    </li>
                    <li :class="{ activeico: true }">
                      <img src="@/assets/ico/AnalyseSang.webp" alt="" />
                      <p>{{ $t("pageMatrice.accordionItems[2].title") }}</p>
                    </li>
                    <li :class="{ activeico: true }">
                      <img src="@/assets/ico/tempsReel.webp" alt="" />
                      <p>{{ $t("pageMatrice.accordionItems[3].title") }}</p>
                    </li>
                    <li :class="{ activeico: true }">
                      <img src="@/assets/ico/mémoire.webp" alt="" />
                      <p>{{ $t("pageMatrice.accordionItems[4].title") }}</p>
                    </li>
                  </ul>
                  <ul class="list-capteurs">
                    <li :class="{ activeico: button == 1 }">
                      <img src="@/assets/ico/capteurs.webp" alt="" />
                      <p>{{ $t("pageMatrice.accordionItems[8].title") }}</p>
                    </li>
                    <li :class="{ activeico: button == 1 }">
                      <img src="@/assets/ico/actionneurs.webp" alt="" />
                      <p>{{ $t("pageMatrice.accordionItems[9].title") }}</p>
                    </li>
                    <li :class="{ activeico: button == 1 }">
                      <img src="@/assets/ico/position.webp" alt="" />
                      <p>{{ $t("pageMatrice.accordionItems[7].title") }}</p>
                    </li>
                    <li :class="{ activeico: button == 2 }">
                      <img src="@/assets/ico/ecriturePasseport.webp" alt="" />
                      <p>{{ $t("pageMatrice.accordionItems[11].title") }}</p>
                    </li>
                    <li :class="{ activeico: button == 2 }">
                      <img src="@/assets/ico/ECG.webp" alt="" />
                      <p>{{ $t("pageMatrice.accordionItems[10].title") }}</p>
                    </li>
                    <li :class="{ activeico: button == 1 }">
                      <img src="@/assets/ico/personnalisable.webp" alt="" />
                      <p>{{ $t("pageMatrice.accordionItems[12].title") }}</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="button-position">
                <router-link to="/contact?subjectIndex=1" class="link-download" download>{{ $t("button.datasheet")
                  }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script scoped>
import tableauFormatsMatrice from "./tableauFormatsMatrice.vue";

export default {
  components: { tableauFormatsMatrice },
  data() {
    return {
      button: 1,
    };
  },
  methods: {
    toggleAccordion(index) {
      this.$set(this.pageMatrice.accordionItems, index, {
        ...this.pageMatrice.accordionItems[index],
        active: !this.pageMatrice.accordionItems[index].active,
      });
    },
  },
  computed: {
    downloadLink() {
      return this.$i18n.locale === "fr"
        ? "/pdf/MiceTrackingDatasheetFR.pdf"
        : "/pdf/MiceTrackingDatasheetUS.pdf";
    },
  },
};
</script>

<style scoped>
ul {
  padding-left: 0px !important;
}

.plateformes-container {
  border-radius: 25px;
  background-color: var(--white);
  padding: 20px;
  min-width: fit-content;
}

.list-container {
  margin-top: 25px;
  position: sticky;
  top: 0px;
  background-color: var(--white);
  z-index: 1000;
}

.item-implant li {
  text-align: center;
}

.item-implant button:hover {
  opacity: 0.7;
}

.item-implant li div {
  width: 0px;
  height: 3px;
  background-color: var(--vertfonce);
  transition: width 0.5s ease;
  /* Animation sur la hauteur */
}

.item-implant li h3.active {
  color: var(--vertfonce);
  /* Hauteur finale lors de l'animation */
}

.item-implant li div.active {
  width: 100%;
  /* Hauteur finale lors de l'animation */
}

.implant-container-inferior {
  margin-top: 55px;
  height: fit-content;
}

.part-1 {
  width: 45%;
}

.part-2 {
  width: 45%;
}

.tilte-part {
  margin-bottom: 15px !important;
}

.image-implant-container {
  position: sticky;
  top: 120px;
  text-align: center;
  align-content: center;
}

.image-implant-container img {
  position: relative;
  z-index: 2;
  width: 80%;
  display: none;
}

.image-implant-container img.active {
  display: block;
}

.background-capteurs {
  /* background-color: var(--background); */
  border-radius: 20px;
  /* border: solid 1px var(--noirdoux); */
  width: 100%;
  min-width: fit-content;
}

.button-position {
  display: flex;
  justify-content: right;
  margin-top: 50px;
}

.information-implant {
  margin-top: 30px;
  animation: fadeInUp 0.5s ease-in-out forwards;
  display: none;
}

.information-implant.active {
  display: block;
}

.information-implant p {
  text-align: justify;
}

.formats div {
  padding-right: 10px;
}

.formats p {
  font-size: 12px;
}

.sous-titre {
  display: none;
}

.list-capteurs {
  padding-left: 0px !important;
  width: 75%;
}

.background-capteurs {
  border-radius: 20px;
  width: 100%;
  margin-top: 40px;
  min-width: fit-content;
}

.button-position {
  display: flex;
  justify-content: right;
  margin-top: 50px;
}

.list-capteurs li {
  display: none;
  justify-content: left;
  align-items: center;
  opacity: 0;
  transform: translateX(-20px);
  /* Positionne les éléments légèrement hors de l'écran à gauche */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.list-capteurs li.activeico {
  display: flex;
  opacity: 1;
  transform: translateX(0);
}

.list-capteurs li img {
  width: 62px;
  height: 50px;
}

.list-capteurs li p {
  margin-top: 16px;
  margin-left: 10px;
  font-family: "roboto", light;
  font-size: 16px;
}

.list-capteurs {
  list-style: disc;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInlow {
  0% {
    opacity: 0.5;
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@media (max-width: 960px) {
  .implant-container {
    background-color: transparent;
    border: none;
    padding: 0;
  }

  .implant-container-inferior {
    margin-top: 50px;
  }

  .item-implant li {
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  .item-implant li p {
    display: none;
  }

  .item-implant li.inactive {
    transform: scale(0.7);
    /* Réduit la taille des éléments inactifs */
    opacity: 0.5;
    /* Rend les éléments inactifs moins opaques */
  }

  .item-implant li.active {
    transform: scale(1);
    /* Taille normale de l'élément actif */
    opacity: 1;
    /* Pleine opacité pour l'élément actif */
  }

  .item-implant button {
    font-weight: 400;
    font-size: 28px;
  }

  .sous-titre {
    display: block;
    text-align: center;
  }

  summary {
    font-size: 20px;
  }

  .item-implant li.active {
    display: block;
  }

  .implant-container-inferior {
    display: block;
  }

  .part-2,
  .part-1 {
    width: 100%;
  }

  .capteurs {
    margin-top: 50px;
  }

  .description {
    margin-top: 50px;
  }

  .background-capteurs {
    background-color: #fff;
  }

  .image-implant-container {
    margin-top: 50px;
  }

  .image-implant-container img {
    width: 80%;
  }

  .tilte-part {
    text-align: center;
  }

  .button-position {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 20px;
  }
}
</style>
