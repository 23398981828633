<template>
  <div>
    <navbar />
    <image-plateformes />
    <plateformes-liste class="mt100" />
    <plateformes-personnalisables class="mt100" />
    <footer-vue class="mt100" />
  </div>
</template>

<script>
import navbar from "@/components/NavBar.vue";
import imagePlateformes from "@/components/PagePlateformes/PlateformesImage.vue";
import PlateformesListe from "@/components/PagePlateformes/PlateformesListe.vue";
import PlateformesPersonnalisables from "@/components/PagePlateformes/PlateformesPersonnalisables.vue";
import FooterVue from "@/components/FooterBis.vue";

export default {
  components: {
    navbar,
    PlateformesListe,
    imagePlateformes,

    PlateformesPersonnalisables,
    FooterVue,
  },
  metaInfo() {
    return {
      title: this.$t("pageMatrice.metaTitle"),
      link: [
        {
          rel: "canonical",
          href: `https://micetracking.com${this.$route.path}`,
        },
      ],
      meta: [
        {
          name: "description",
          content: this.$t("pageMatrice.metaDescription"),
        },
        {
          name: "keywords",
          content: this.$t("pageMatrice.metaKeywords"),
        },
      ],
    };
  },
};
</script>
